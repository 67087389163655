import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./index.css"

const IndexPage = ({
  data: {
    posts: { edges },
    introImage,
    footerImage,
  },
}) => {
  const posts = edges.map(edge => {
    const {
      id,
      timeToRead,
      wordCount,
      frontmatter: { title, path, date, featuredImage },
    } = edge.node
    return (
      <div className="w-full mb-5 px-4 sm:w-1/2 md:w-1/3 lg:w-1/4" key={id}>
        <Link className="no-underline" to={path}>
          <Img
            className="rounded-lg zoom-image sm:h-64"
            fluid={featuredImage.childImageSharp.fluid}
          />
          <h1 className="text-black mt-2 hover:text-red-500">{title}</h1>
        </Link>
        <span className="capitalize text-gray-500 text-sm pr-3">{date}</span>
        <span className="text-gray-500 text-sm text-gray-900">
          <i className="fas fa-book-reader pr-1"></i>
          {timeToRead} min. ({wordCount.words} slov)
        </span>
      </div>
    )
  })
  return (
    <Layout>
      <SEO title="Domov" />
      <div className="flex relative content-around items-center mb-5">
        <Img
          className="w-full h-56 md:h-64"
          fluid={introImage.childImageSharp.fluid}
        />
        <p className="motto absolute rounded-lg left-1/2 bottom-0 translate-bottom-center ml-auto mr-auto text-xl text-black text-center w-3/4 md:w-1/2 p-3">
          Gastronomik je ako sušienka, plnochutne napísaný z{"\u00a0"}lásky a
          vôní kuchyne.
        </p>
      </div>
      <p className="self-center text-center w-2/4 pt-8 pb-4 text-xl ml-auto mr-auto">
        Našou vášňou je jedlo - dobré jedlo. Gastronomik sa pre nás stal
        platformou, kde túto vášeň môžeme zdieľať so svetom. Gastronomik - blog,
        v ktorom nájdete plnochutné myšlienky o jedle z rôznych kútov Zeme a
        ktoré poteší ktorýkoľvek chuťový pohárik.
      </p>
      <hr className="my-10 mx-20" />
      <div className="flex flex-wrap mx-3 my-10">{posts}</div>
      <hr className="my-10 mx-20" />
      <Img
        className="w-full h-56 md:h-64"
        fluid={footerImage.childImageSharp.fluid}
      />
    </Layout>
  )
}

export default IndexPage
export const pageQuery = graphql`
  query {
    introImage: file(
      relativePath: { eq: "edgar-castrejon-1SPu0KT-Ejg-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    footerImage: file(
      relativePath: { eq: "jay-wennington-N_Y88TWmGwA-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          timeToRead
          wordCount {
            words
          }
          frontmatter {
            path
            date(locale: "sk", formatString: "LL")
            title
            featuredImage {
              childImageSharp {
                fluid(quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
